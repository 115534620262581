import React, {FunctionComponent} from 'react';
import Button from './Button';
import {DialogStateReturn} from 'reakit/Dialog';

interface Props {
    dialog: DialogStateReturn;
    externalLink: string;
}

const ExternalLinkModalContent: FunctionComponent<Props> = ({dialog, externalLink}) => {
    require(`../styles/${process.env.THEME}/external-link-modal.scss`);
    const {title, text}: { title: string, text: string } = require(`../content/${process.env.THEME}/exit-site-modal.json`);

    return (
        <div className={'external-link-modal'}>
            <div className='stacky-8'>
                <h1 className={'text-2xl text-gray-700 font-bold'}>{title}</h1>
                <p>{text}</p>
            </div>
            <div className='flex flex-row mt-2 lg:mt-12 stackx-4'>
                <Button
                    className='flex-grow cancel'
                    buttonHandler={(e) => {
                        e.preventDefault();
                        dialog.hide();
                    }}
                >
                    Terug
                </Button>

                <a
                    href={externalLink}
                    className={'button text-center px-6 py-2 border rounded-md font-bold uppercase flex-grow continue'}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => dialog.hide()}
                >
                    Verder
                </a>
            </div>
        </div>
    );
};

export default ExternalLinkModalContent;
