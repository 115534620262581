import React, {FunctionComponent} from 'react';
import {Dialog, DialogBackdrop, DialogProps} from 'reakit/Dialog';
import {Button} from 'reakit/Button';

interface Props {
    dialog: DialogProps;
}

const Modal: FunctionComponent<Props> = ({dialog, children}) => (
    <>
        <DialogBackdrop {...dialog} className='fixed inset-0 z-50 flex items-center justify-center'>
            <div className='absolute inset-0 bg-gray-900 opacity-75'/>
            <Dialog {...dialog} aria-label='Welcome' className='relative max-w-4xl mx-auto '>
                <div className='relative max-h-screen overflow-y-auto '>
                    <div className='p-4 bg-white shadow lg:p-12'>

                        <Button className='float-right w-8 h-8 p-2 border border-gray-600 border-solid rounded-full' onClick={dialog.hide}>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' className='fill-current'>
                                <defs>
                                    <clipPath>
                                        <path d='M0 0h45v45H0z'/>
                                    </clipPath>
                                </defs>
                                <g clipPath='url(#a)'>
                                    <path
                                        data-name='Path 59'
                                        d='M45 4.532L40.468 0 22.5 17.968 4.532 0 0 4.532 17.968 22.5 0 40.468 4.532 45 22.5 27.032 40.468 45 45 40.468 27.032 22.5z'/>
                                    <path data-name='Path 60' d='M1 1h44.286v44.286H1z' fill='none'/>
                                </g>
                            </svg>
                        </Button>

                        {children}
                    </div>
                </div>
            </Dialog>
        </DialogBackdrop>
    </>
);

export default Modal;
