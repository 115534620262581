import React from 'react';
import {FunctionComponent} from 'react';

interface Props {
    class:string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NewsItem: FunctionComponent<Props> = ({children}) => {

    return (
        <div className={'news-item'}>
            {children}
        </div>
    );

};

export default NewsItem;
