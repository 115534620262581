import React, {FunctionComponent} from 'react';
import {Link} from 'gatsby';

interface Props {
    name: string;
    link: string;
    classNames: string;
}

const SiteLogo: FunctionComponent<Props> = ({name, link, classNames}) => {

    return (
        <Link
            className={classNames + ' site-logo'}
            to={link}
        >
            <svg xmlns='http://www.w3.org/2000/svg' height='46' viewBox='0 0 260 72'>
                <g data-name='Group 79'>
                    <text
                        data-name={name}
                        fill='#fff'
                        fontSize='25'
                        fontWeight='700'
                        textAnchor={'middle'}
                        x={'50%'}
                        y={'50%'}
                        dominantBaseline={'middle'}
                    >
                        {name}
                    </text>
                    <g data-name='Rectangle 9' className={'rectangle'} fill='none' stroke='#fff' strokeWidth='4'>
                        <path stroke='none' d='M0 0h260v72H0z'/>
                        <path d='M2 2h256v68H2z'/>
                    </g>
                </g>
            </svg>
        </Link>
    );
};

export default SiteLogo;
