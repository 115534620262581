import React, {FunctionComponent, useState} from 'react';
import {Link} from 'gatsby';
import SiteLogo from './SiteLogo';
import NavLink from '../../types/NavLink';
import SanofiLogo from './SanofiLogo';
import {DialogDisclosure, useDialogState} from 'reakit/Dialog';
import Modal from '../Modal';
import ExternalLinkModalContent from '../ExternalLinkModalContent';
import {usePermissionData} from '../../lib/permissions/PermissionContext';
import Anchor from '../Anchor';

interface NavigationItem extends NavLink {
    children?: Array<NavLink>;
}

type NavStructure = Array<NavigationItem>;

const Header: FunctionComponent = () => {
    const {
        navigation,
        logoText,
        sanofiLogoColor
    }: { navigation: NavStructure, logoText: string, sanofiLogoColor: string } = require(`../../content/${process.env.THEME}/header.json`);

    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [visibleSubMenu, setVisibleSubMenu] = useState<number | null>(null);
    const dialog = useDialogState();
    const [current, setCurrent] = useState("");
    const closeMenu = () => {
        setVisibleSubMenu(null);
    };
    const {state} = usePermissionData();

    const handleClick = (e) => {
        if (state && state.bigCode) {
            // Perform the permission check here
            // If the permission check passes, allow navigation
            return true;
        } else {
            // If the permission check fails, prevent navigation

            e.preventDefault();
            return false;
        }
    };
    return (
        <header className='bg-white lg:sticky lg:top-0 lg:z-40'>
            <div className='px-6 pt-2 pb-2 text-sm text-white lg:stacky-6'>
                <div className='container flex flex-col mx-auto  justify-between'>
                    <div className='align-middle self-end'>
                        <SanofiLogo grayScale={false} color={sanofiLogoColor}/>
                    </div>
                </div>
            </div>
            <nav className='flex flex-col font-bold text-white shadow'>
                <div className='container items-center justify-between lg:flex lg:flex-row'>
                    <div className='flex flex-row items-center justify-between lg:px-0 px-6'>
                        <SiteLogo name={logoText} link={'/'} classNames='block my-4'/>

                        {/* Mobile hamburger button */}
                        <button
                            type='button'
                            onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
                            className={'inline-block lg:hidden'}
                        >
                            <span>
                                {mobileMenuVisible ?
                                    <svg xmlns='http://www.w3.org/2000/svg' className='w-6 h-6 fill-current' viewBox='0 0 24 24'>
                                        <defs>
                                            <path d='M0 0h24v24H0z'/>
                                        </defs>
                                        <g clipPath='url(#b)'>
                                            <path
                                                d='M24 2.42L21.58 0 12 9.58 2.42 0 0 2.42 9.58 12 0 21.58 2.42 24 12 14.42 21.58 24 24 21.58 14.42 12 24 2.42z'/>
                                            <path d='M.53.53h23.62v23.62H.53V.53z' fill='none'/>
                                        </g>
                                    </svg>
                                    :
                                    <svg xmlns='http://www.w3.org/2000/svg' className='w-6 h-6 fill-current' viewBox='0 0 24 24'>
                                        <line x1='0' y1='3' x2='24' y2='3' style={{strokeWidth: '4'}}/>
                                        <line x1='0' y1='11' x2='24' y2='11' style={{strokeWidth: '4'}}/>
                                        <line x1='0' y1='19' x2='24' y2='19' style={{strokeWidth: '4'}}/>
                                    </svg>
                                }
                            </span>
                        </button>

                    </div>
                    <ul className={`${mobileMenuVisible ? 'block static stacky-2' : 'hidden'} lg:flex lg:flex-grow lg:ml-16 menuList mb-4 lg:mb-0`}>
                        {
                            navigation.map((navLink, index) =>
                                <li
                                    key={index}
                                    className={'relative px-4 lg:px-0 mr-12 parent'}
                                    onMouseLeave={() => window.innerWidth >= 1024 && closeMenu()}
                                >
                                    {/* Desktop nav link */}
                                    {
                                        navLink.to ?
                                            !(navLink.to === 'https://www.sanofi.nl/nl/contact') ? <Anchor
                                                to={navLink.to}
                                                className={`${navLink.children && navLink.children.length > 0 && 'hidden'} lg:text-center items-center justify-between block w-full h-full font-bold lg:flex navlink`}
                                                onMouseEnter={() => setVisibleSubMenu(index)}
                                            >
                                                {navLink.title}
                                            </Anchor> : <>
                                                <DialogDisclosure {...dialog}>
                                                    <a
                                                        href={'#'}
                                                        className={`${navLink.children && navLink.children.length > 0 && 'hidden'} lg:text-center items-center justify-between block w-full h-full font-bold lg:flex navlink`}
                                                        onMouseEnter={() => setVisibleSubMenu(index)}
                                                    >
                                                        {navLink.title}
                                                    </a>
                                                </DialogDisclosure>
                                                <Modal dialog={dialog}>
                                                    <ExternalLinkModalContent dialog={dialog}
                                                                              externalLink={navLink.to}/>
                                                </Modal>
                                            </>
                                            :
                                            <div
                                                className={`${navLink.children && navLink.children.length > 0 && 'hidden'} lg:text-center items-center justify-between block w-full h-full font-bold lg:flex navlink`}
                                                onMouseEnter={() => setVisibleSubMenu(index)}
                                            >
                                                {navLink.title}
                                            </div>
                                    }

                                    {/* Mobile button */}
                                    {
                                        navLink.children && navLink.children.length > 0 &&
                                        <button
                                            onClick={() => setVisibleSubMenu(visibleSubMenu === index ? null : index)}
                                            className='flex items-center justify-between block w-full font-bold mr-12 lg:hidden'
                                        >
                                            <span>{navLink.title}</span>
                                            <svg
                                                fill='currentColor'
                                                viewBox='0 0 20 20'
                                                className='w-6 h-6 fill-current lg:hidden'
                                            >
                                                <path
                                                    fillRule='evenodd'
                                                    d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                                    clipRule='evenodd'
                                                />
                                            </svg>
                                        </button>
                                    }
                                    {
                                        navLink.children && navLink.children.length > 0 &&
                                        <ul
                                            className={`${visibleSubMenu === index ? 'block lg:py-4 mt-2 lg:mt-0 lg:absolute z-20 lg:w-full lg:bg-white lg:rounded-md lg:shadow' : 'hidden'}`}
                                            style={{minWidth: 'max-content'}}
                                        >
                                            {/* Mobile nav link */}
                                            {
                                                navLink.to &&
                                                <li key={navLink.to} className={'lg:hidden md:block'}>
                                                    <Link
                                                        to={navLink.to}
                                                        onClick={handleClick}
                                                        className={'px-4 py-2 block lg:hover:bg-yellow-600 font-normal lg:hover:font-bold'}
                                                    >
                                                        {navLink.title}
                                                    </Link>
                                                </li>
                                            }

                                            {navLink.children.map((childNavLink, childIndex) => (
                                                <li key={`${index}-${childIndex}`}>
                                                    <Link
                                                        to={childNavLink.to}
                                                        className={'px-4 py-2 block lg:hover:bg-yellow-600 font-normal lg:hover:font-bold child-item'}
                                                        activeClassName={childNavLink.disableHighlight ? '' : 'font-bold'}
                                                    >
                                                        {childNavLink.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </li>
                            )
                        }
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;
