import styled from 'styled-components';
import Chart from "./Chart";
import * as Icon from "react-icons/fi";
import parse from 'html-react-parser';
import Checkbox from "react-custom-checkbox";
import {useState, useEffect} from "react";
const Legend = styled.div`
    display:flex;
`;
const ChartArea = styled.div`
    display:flex;
`;
const Container = styled.div`
    padding: 2em;
    position:relative;
    display:flex;
    width:800px;
    height:400px;
`;

const List = styled.ul`
    list-style-type: none;
    text-align: left;
    span {
        font-weight: normal;
    }
`;
const ListItem = styled.li`
    padding:1em;
    margin-bottom:1em;
    border:1px solid #f0f0f0;
    border-radius:0.2em;
    cursor:pointer;
`;

function ChartHolder({dataset}) {

    const [selected, setSelected] = useState([]);

    useEffect(()=>{

        let active = [];

        for(let i = 0; i < dataset.length; i++) {
            active.push(i);
        }
        setSelected(active);

    }, []);

    return (
        <Container>
            <Legend>
                <List>
                    {dataset.map((data, index)=>{
                        return <ListItem key={index}> <Checkbox
                            icon={<Icon.FiCheck color="#174A41" size={22} />}
                            name="my-input"
                            checked={selected.indexOf(index)!==-1}
                            onChange={(value, event) => {
                                let arr = [...selected];

                                if(value) {
                                    arr.push(index);
                                } else {
                                    arr.splice(arr.indexOf(index),1);
                                }
                                setSelected(arr);
                            }}
                            borderColor={data.borderColor}
                            style={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 5, userSelect: "none" }}
                            label={parse(data.label)}
                        /></ListItem>
                    })}
                </List>
            </Legend>
            <ChartArea>
                {dataset.map((data, index)=>{
                    return <Chart key={index} dataset={data} visible={selected.indexOf(index)!==-1}/>
                })}
            </ChartArea>
        </Container>
    );
}

export default ChartHolder;