module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"https://www.praluent.nl","short_name":"Sanofi","start_url":"/","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"44e34493987a307c5f802d28bfd9aca3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(excluded-link|external)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-classes","options":{"classMap":{"heading[depth=1]":"text-4xl font-bold my-6","heading[depth=2]":"text-2xl font-bold my-4","heading[depth=3]":"text-2xl text-gray-700 font-bold my-2","heading[depth=4]":"text-base text-gray-700 font-bold","heading[depth=5]":"text-lg text-gray-700 font-bold my-2","heading[depth=6]":"text-base text-gray-700 font-bold my-2","paragraph":"text-base text-gray-700 mb-4 leading-relaxed","list[ordered=false]":"pl-8 list-disc leading-relaxed","link":"text-blue-700"}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":590,"wrapperStyle":"margin-left: 0px;","quality":90,"disableBgImageOnAlpha":true,"linkImagesToOriginal":false}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/jeeveegee/Projects/diabetes_websites_nl","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
