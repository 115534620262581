import React, {FunctionComponent} from 'react';
import {DialogDisclosure, useDialogState} from 'reakit/Dialog';
import Modal from '../Modal';
import ExternalLinkModalContent from '../ExternalLinkModalContent';

interface Props {
    grayScale?: boolean;
    className?: string;
    color?: string;
}

const SanofiLogo: FunctionComponent<Props> = ({grayScale = false, className = 'text-gray-600', color}) => {
    const dialog = useDialogState();

    return (
        <>
            <DialogDisclosure {...dialog}>
                {grayScale ?
                    <WhiteLogo className={className}/>
                    :
                    <ColorLogo color={color ?? 'black'}/>
                }
            </DialogDisclosure>
            <Modal dialog={dialog}>
                <ExternalLinkModalContent dialog={dialog} externalLink='https://www.sanofi.nl/'/>
            </Modal>
        </>
    );

};

export default SanofiLogo;

interface WhiteLogoProps {
    className: string;
}

const WhiteLogo: FunctionComponent<WhiteLogoProps> = ({className, props}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={'160'} viewBox="0 0 85.65 22" {...props}>
        <defs>
            <style>{".cls-1{fill:#fff}"}</style>
        </defs>
        <g id="Laag_2" data-name="Laag 2">
            <g id="Laag_1-2" data-name="Laag 1">
                <path
                    className="cls-1"
                    d="M30.41 7a16.7 16.7 0 0 0-6.1-1.14c-4.68 0-8 3.13-8 8.05S19.2 22 24.25 22a15.4 15.4 0 0 0 6.16-1.24 1.19 1.19 0 0 0 .78-1.24V8.27A1.15 1.15 0 0 0 30.41 7Zm-3.25 10.17c0 .46-.25.68-.78.83a7.21 7.21 0 0 1-2.1.28c-2 0-3.84-1.18-3.84-4.34s1.85-4.33 3.84-4.33a7.58 7.58 0 0 1 2.1.27c.53.16.78.38.78.84ZM4.32 11.06c0-1 1-1.55 2.61-1.55a9.65 9.65 0 0 1 4.67 1.4.91.91 0 0 0 .47.12.81.81 0 0 0 .68-.43l1-2a1.24 1.24 0 0 0 .09-.6.7.7 0 0 0-.38-.62 12.87 12.87 0 0 0-6.41-1.62C2.9 5.76.23 8 .23 11.34c0 6.57 10 4 10 8.58a3.27 3.27 0 0 1-.23 1.15 1.08 1.08 0 0 0 0 .19.42.42 0 0 0 .47.43h2.79c.46 0 .65-.15.8-.62a5.7 5.7 0 0 0 .25-1.77c-.01-7.03-9.99-5.3-9.99-8.24ZM42 5.89A18.79 18.79 0 0 0 35.74 7 1.19 1.19 0 0 0 35 8.24v12.83a.59.59 0 0 0 .62.62h2.79a.59.59 0 0 0 .62-.62V10.66c0-.47.24-.68.74-.81A9.1 9.1 0 0 1 42 9.61c1.68 0 3 .77 3 2.63v8.83a.59.59 0 0 0 .62.62h2.79a.59.59 0 0 0 .62-.62v-9.36c.04-3.78-2.29-5.82-7.03-5.82ZM59.73 5.89c-4.65 0-7.73 3.37-7.73 8.05S55 22 59.9 22c4.65 0 7.69-3.38 7.69-8.06s-3-8.05-7.86-8.05Zm.21 12.39c-2.29 0-3.81-1.49-3.81-4.34s1.52-4.33 3.6-4.33c2.29 0 3.81 1.48 3.81 4.33s-1.54 4.34-3.6 4.34Z"
                />
                <rect
                    className="cls-1"
                    x={81.48}
                    y={6.2}
                    width={4.03}
                    height={15.49}
                    rx={0.62}
                />
                <path
                    className="cls-1"
                    d="M78.94.22A7.91 7.91 0 0 0 77 0c-3.84 0-6.35 2.05-6.35 6.51v14.56a.59.59 0 0 0 .62.62H74a.59.59 0 0 0 .62-.62V9.61h3.93a.57.57 0 0 0 .63-.61V6.85a.58.58 0 0 0-.62-.65h-3.93v-.34c0-1.71.74-2.58 2.45-2.58.62 0 1.28.16 1.52.16s.52-.22.58-.59l.31-1.61a2 2 0 0 0 .07-.4c0-.35-.19-.53-.62-.62ZM2.11 17.72A2 2 0 0 0 0 19.86 2 2 0 0 0 2.2 22a2 2 0 0 0 2.11-2.14 2 2 0 0 0-2.2-2.14ZM83.45 0a2 2 0 0 0-2.11 2.13 2 2 0 0 0 2.2 2.14 2 2 0 0 0 2.11-2.14A2 2 0 0 0 83.45 0Z"
                />
            </g>
        </g>
    </svg>
);

const ColorLogo = ({color, props}: { color: string, props: any }) => (
    <svg
        width={'60'}
        data-name="sanofi-logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 39.82 10.23"
        {...props}
    >
        <path d="M14.14 3.27a7.72 7.72 0 0 0-2.83-.53A3.52 3.52 0 0 0 7.6 6.48a3.41 3.41 0 0 0 3.68 3.75 7.1 7.1 0 0 0 2.86-.58.55.55 0 0 0 .36-.57V3.85a.53.53 0 0 0-.36-.58ZM12.63 8c0 .22-.11.32-.36.39a3.32 3.32 0 0 1-1 .13c-.92 0-1.79-.55-1.79-2s.87-2 1.79-2a3.32 3.32 0 0 1 1 .13c.25.07.36.17.36.38ZM2.01 5.14c0-.44.45-.72 1.21-.72a4.57 4.57 0 0 1 2.18.65.43.43 0 0 0 .22.06.35.35 0 0 0 .31-.2L6.38 4a.65.65 0 0 0 .06-.25.34.34 0 0 0-.18-.29 6 6 0 0 0-3-.76C1.33 2.7.09 3.7.09 5.29c0 3.06 4.64 1.87 4.64 4a1.57 1.57 0 0 1-.09.54s0 0 0 .08.07.2.22.2h1.3a.34.34 0 0 0 .38-.28A3 3 0 0 0 6.65 9c0-3.3-4.64-2.51-4.64-3.86ZM19.52 2.74a8.57 8.57 0 0 0-2.9.52.54.54 0 0 0-.36.57v6a.27.27 0 0 0 .29.28h1.27a.26.26 0 0 0 .28-.28V5c0-.22.12-.32.35-.38a4 4 0 0 1 1.07-.11c.77 0 1.39.36 1.39 1.22V9.8a.27.27 0 0 0 .29.28h1.3a.26.26 0 0 0 .32-.28V5.45c0-1.76-1.09-2.71-3.3-2.71ZM27.82 2.74a3.48 3.48 0 0 0-3.57 3.74 3.62 3.62 0 1 0 7.24 0 3.51 3.51 0 0 0-3.67-3.74Zm.1 5.76c-1.06 0-1.77-.69-1.77-2s.71-2 1.67-2 1.77.69 1.77 2-.77 2-1.72 2Z" />
        <rect x={37.88} y={2.88} width={1.87} height={7.2} rx={0.29} />
        <path d="M36.7.1a3.91 3.91 0 0 0-.88-.1 2.7 2.7 0 0 0-3 3v6.8a.27.27 0 0 0 .29.28h1.28a.27.27 0 0 0 .29-.28V4.47h1.83a.27.27 0 0 0 .31-.29v-1a.27.27 0 0 0-.29-.3H34.7v-.16c0-.79.34-1.19 1.14-1.19.29 0 .6.07.7.07s.25-.1.28-.27l.14-.75a1.48 1.48 0 0 0 0-.19c.03-.16-.06-.25-.26-.29Z" />
        <path
            d="M3.16 10.24a.92.92 0 0 0-1 1 .94.94 0 0 0 1 1 .93.93 0 0 0 1-1 .93.93 0 0 0-1-1ZM41 2a.92.92 0 0 0-1 1 .94.94 0 0 0 1 1 .93.93 0 0 0 1-1 .94.94 0 0 0-1-1Z"
            transform="translate(-2.18 -2)"
            style={{
                fill: "#7a00e6",
            }}
        />
    </svg>
);
