import React, {createContext, FunctionComponent, useContext, useReducer} from 'react';
import {initialState, PermissionAction, PermissionState, reducer} from './PermissionReducer';

type PermissionContextProps = {
    state: PermissionState;
    dispatch: (permissionAction: PermissionAction) => void;
}
export const PermissionContext = createContext({state: initialState} as PermissionContextProps);

const PermissionContextProvider: FunctionComponent = ({children}) => {
    let allowed = false;

    const storage = localStorage.getItem('allowTracking');
    if (storage) {
        allowed = JSON.parse(storage) || false;
    }
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        allowTracking: allowed
    });

    return (
        <PermissionContext.Provider value={{state, dispatch}}>
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionContextProvider;

export const usePermissionData = (): PermissionContextProps => useContext(PermissionContext);
