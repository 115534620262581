import React from 'react';
import {FunctionComponent} from 'react';
import Iframe from './Iframe';

interface Props {
    type: string;
    url: string;
    video:boolean;
    className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Podcast: FunctionComponent<Props> = ({url,video}) => {

    const PodcastPlayer = () => (
        <>
            <Iframe url={url} height={(video) ? '300' : '300'}
            />
        </>
    );

    return (
        <PodcastPlayer/>
    );

};

export default Podcast;
