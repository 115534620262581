import React from 'react';
import {FunctionComponent} from 'react';

interface Props {
    type: string;
    url: string;
    video:boolean;
    className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OTScriptCookieBtn: FunctionComponent<Props> = () => {

    const OTScriptCookieBtn = () => (
        <>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
        </>
    );

    return (
        <OTScriptCookieBtn/>
    );

};

export default OTScriptCookieBtn;
