import React from 'react';
import {FunctionComponent} from 'react';

interface Props {
    type: string;
    url: string;
    className?: string;
}

import image from "../images/praluent/vergoedingsvoorwaarden.png";
import image2 from "../images/praluent/artsenverklaring.png";
import image3 from "../images/praluent/artsenverklaring-overzicht.png";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @todo : make dynamic image
const Image: FunctionComponent<Props> = ({type, url, className, }) => {

    if(type === "artsenverklaringOverzicht") {
        return (
            <img src={image3} />
        );
    }

    if(type === "artsenverklaring") {
        return (
            <img src={image2} />
        );
    }

    return (
        <img src={image} />
    );


};

export default Image;
