import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';

const Container = styled.div`
    position:absolute;
    width:800px;
`;

export const options = {
    type: 'line',
    elements: {
        line: {
            tension: 0.5,
            borderJoinStyle:'bevel'
        }
    },
    responsive: true,
    options: {
        maintainAspectRatio: false,
    },
    scales: { y: { title: {
                display: true,
                text: 'GIR* (mg/(kg*min))'
            }, min: 0, max: 12,
            grid: {
                display: true, drawBorder:true, drawOnChartArea:false
            }
        },
        x: {
        title: {
                display: true,
                text: 'Tijd na SC injectie (uren)'
            },
            grid: {
                display: true, drawBorder:true, drawOnChartArea:false
            }
        }
    },
    radius: 0,
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display:false,
            position: "left",
            align: "center",
            labels: {
                boxWidth:25,
                boxHeight:25,
                usePointStyle: false,
            }
        },
        title: {
            display: false,
            text: 'People',
        },
    },
};

const labels = Array.from(Array(37), (_,x) => x);


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

function Chart({dataset, visible}) {
    return (
        <Container className="chart">
            {visible && <Line options={options} data={
                {
                    labels,
                    datasets: [dataset],
                }
            } /> }
        </Container>
    );
}

export default Chart;