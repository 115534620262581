import React, {FunctionComponent} from 'react';

interface Props {
    url: string;
    height:string;
}

const Iframe: FunctionComponent<Props> = ({url,height}) =>{
    return (
        <iframe src={url} width="800" height={height} scrolling="no" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen webkitallowfullscreen
        />
    );
};

export default Iframe;
