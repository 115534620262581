export interface PermissionState {
    euCode: string | null;
    directAccess: boolean | null;
    bigCode: string | null;
    allowTracking: boolean;
}

let directHash:string | null = '';
if(typeof window !== 'undefined') {
    directHash = new URLSearchParams(window.location.search).get('hash');
}

export const initialState: PermissionState = {
    euCode: null,
    directAccess: (directHash === process.env.THEME + '-direct'),
    bigCode: null,
    allowTracking: false
};

export enum PermissionActionType {
    SET_EU_CODE = 'SET_EU_CODE',
    SET_BIG_CODE = 'SET_BIG_CODE',
    SET_ALLOW_TRACKING = 'SET_ALLOW_TRACKING',
    SET_DIRECT_ACCESS = 'SET_DIRECT_ACCESS'
}

interface SetEUCodeAction {
    type: PermissionActionType.SET_EU_CODE;
    payload: string;
}

interface SetDirectAccess {
    type: PermissionActionType.SET_DIRECT_ACCESS;
    payload: string;
}

interface SetBIGCodeAction {
    type: PermissionActionType.SET_BIG_CODE;
    payload: string;
}

interface SetAllowTrackingAction {
    type: PermissionActionType.SET_ALLOW_TRACKING
}

export type PermissionAction = SetEUCodeAction | SetBIGCodeAction | SetAllowTrackingAction | SetDirectAccess

export const reducer = (state: PermissionState, action: PermissionAction): PermissionState => {
    switch (action.type) {
        case PermissionActionType.SET_DIRECT_ACCESS:
            sessionStorage.setItem('directAccess', 'true');
            return {
                ...state,
                euCode: action.payload
            };
        case PermissionActionType.SET_EU_CODE:
            return {
                ...state,
                euCode: action.payload
            };
        case PermissionActionType.SET_BIG_CODE:
            return {
                ...state,
                bigCode: action.payload
            };
        case PermissionActionType.SET_ALLOW_TRACKING:
            localStorage.setItem('allowTracking', 'true');
            return {
                ...state,
                allowTracking: true
            };
        default:
            return state;
    }
};

