import styled from 'styled-components';
import {useState} from "react";
import ChartHolder from "./ChartHolder";

const Container = styled.div`

`;

const ButtonLeft = styled.button`
    display:inline-flex;
    background-color:${props => props.active ? `blue` : `white`};
    color:${props => props.active ? `white` : `blue`};
    border-radius:0;
    border-top-left-radius:1em;
    border-bottom-left-radius:1em;   
    border:${props => props.active ? `1px solid blue` : `1px solid blue`};
    padding:1em;
    font-weight:bold;
    font-family: inherit;
    font-size:14px;
    cursor:pointer;
`;
const ButtonRight = styled.button`
    display:inline-flex;
    background-color:${props => props.active ? `blue` : `white`};
    color:${props => props.active ? `white` : `blue`};
    border-radius:0;
    border-top-right-radius:1em;
    border-bottom-right-radius:1em;   
    border:${props => props.active ? `1px solid blue` : `1px solid blue`};
    padding:1em;
    font-weight:bold;
   font-family: inherit;
    font-size:14px;
     cursor:pointer;
`;

const basale =  [
    {
        fill: true,
        label: '<span>Lantus®<sup>2</sup></span><ul><li>SoloStar®</li><li>AllStar® PRO</li><li>JuniorSTAR®</li></ul>',
        data: [
            "0.6",
            "0.6",
            "1",
            "1",
            "1.05",
            "1.05",
            "1.1",
            "1.1",
            "1.0",
            "1.0",
            "0.95",
            "0.95",
            "0.9",
            "0.9",
            "0.82",
            "0.82",
            "0.8",
            "0.8",
            "0.75",
            "0.75",
            "0.72",
            "0.72",
            "0.57",
            "0.5",
            "0.45",
            "0.42",
            "0.41",
            "0.4",
            "0.4",
            "0.3",
            "0.1",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0"
        ],
        borderColor: 'rgb(148, 128, 178)',
        backgroundColor: 'rgba(148, 128, 178, 0.9)',
    },
    {
        fill: true,
        label: '<span>Toujeo®<sup>1</sup></span><ul><li>SoloStar®</li><li>DoubleStar®</li></ul>',
        data: [
            "1",
            "1",
            "1",
            "1",
            "1.05",
            "1.05",
            "1.1",
            "1.1",
            "1.0",
            "1.0",
            "0.95",
            "0.95",
            "0.9",
            "0.9",
            "0.82",
            "0.82",
            "0.8",
            "0.8",
            "0.75",
            "0.75",
            "0.72",
            "0.72",
            "0.69",
            "0.69",
            "0.65",
            "0.65",
            "0.65",
            "0.65",
            "0.6",
            "0.6",
            "0.5",
            "0.5",
            "0.4",
            "0.3",
            "0.3",
            "0.1",
            "0"],
        borderColor: 'rgb(199, 210, 121)',
        backgroundColor: 'rgba(199, 210, 121, 0.9)',
    }];

const short =  [{
        fill: true,
        label: '<span>Apidra®<sup>3</sup></span><ul><li>SoloStar®</li><li>AllStar® PRO</li><li>JuniorSTAR®</li></ul>',
        data: [
            {x: 0, y: 0},
            {x: 1, y: 11},
            {x: 2, y: 12},
            {x: 3, y: 11.8},
            {x: 3.8, y: 11},
            {x: 3.9, y: 8},
            {x:5.8, y: 0.8},
            {x: 6.3, y: 0.1},
            {x: 8, y: 0},
            {x: 9, y: 0},
            {x: 10, y: 0},
        ],
        borderColor: 'rgb(85, 113, 173)',
        backgroundColor: 'rgba(85, 113, 173, 0.5)',
    },
    {
        fill: true,
        label: '<span>Insuline aspart Sanofi®<sup>4</sup></span><ul><li>SoloStar®</li><li>AllStar® PRO</li><li>JuniorSTAR®</li></ul>',
        data: [
            {x: 0, y: 0},
            {x: 0.8, y: 6},
            {x: 1.8, y: 8.1},
            {x: 2.3, y: 8.5},
            {x: 3.8, y: 7.8},
            {x: 3.9, y: 5},
            {x: 5.8, y: 2},
            {x: 7.8, y: 0.8},
            {x: 8, y: 0.5},
            {x: 9, y:  0.2},
            {x: 9.5, y:  0.2},
            {x: 10, y:  0}
        ],
        borderColor: 'rgb(100, 100, 100)',
        backgroundColor: 'rgba(100, 100, 100, 0.5)',
    },
    {
        fill: true,
        label: '<span>Insuline lispro Sanofi®<sup>5</sup></span><ul><li>SoloStar®</li><li>AllStar® PRO</li><li>JuniorSTAR®</li></ul>',
        data: [
            {x: 0, y: 0},
            {x: 0.3, y: 0},
            {x: 1.8, y: 8.1},
            {x: 2.3, y: 8.5},
            {x: 3.8, y: 7.8},
            {x: 3.9, y: 5},
            {x: 5.8, y: 2},
            {x: 7.8, y: 0.8},
            {x: 8, y: 0.5},
            {x: 9, y:  0.2},
            {x: 9.5, y:  0.2},
            {x: 10, y:  0}
        ],
        borderColor: 'rgb(230, 220, 125)',
        backgroundColor: 'rgba(230, 220, 125, 0.5)',
    }
    ];

function InsulineChart() {

    const [selected, setSelected] = useState(short);

    return (<>
            <h1>Insuline werkingsprofielen</h1>
            <ButtonLeft active={selected.length === basale.length} onClick={()=>{setSelected(basale)}}>Basale insuline</ButtonLeft>
            <ButtonRight active={selected.length === short.length}  onClick={()=>{setSelected(short)}}>Kortwerkende insulune</ButtonRight>
            <Container>
                <ChartHolder dataset={selected}/>
            </Container>
        </>
    );
}

export default InsulineChart;