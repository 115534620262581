import React from 'react';
import {MDXProvider} from '@mdx-js/react';
import './src/styles/global.scss';
import PermissionContextProvider from './src/lib/permissions/PermissionContext';
import Layout from './src/components/layout/Layout';
import Video from './src/components/Video';
import Anchor from './src/components/Anchor';
import Button from './src/components/Button';
import {track} from './src/lib/permissions/googleUA';
import Podcast from './src/components/Podcast';
import Image from './src/components/Image';
import OTScriptCookieBtn from './src/components/OTScriptCookieBtn';
import OTScriptCookieList from './src/components/OTScriptCookieList';
import InsulineChart from './src/components/chart/InsulineChart';
import NewsItem from './src/components/NewsItem';

const shortcodes = {Video, Anchor, Button, Podcast, NewsItem, InsulineChart, OTScriptCookieBtn, OTScriptCookieList, Image};

export const wrapRootElement = ({element}) => {
    return (
        <PermissionContextProvider>
            <MDXProvider components={shortcodes}>
                <Layout>
                    {element}
                </Layout>
            </MDXProvider>
        </PermissionContextProvider>
    );
};

// Logs when the client route changes
export const onRouteUpdate = ({location}) => {
    track(location.pathname);
};
