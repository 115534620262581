import React from 'react';
import {FunctionComponent} from 'react';

interface Props {
    type: string;
    url: string;
    video:boolean;
    className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OTScriptCookieList: FunctionComponent<Props> = () => {

    const OTScriptCookieList = () => (
        <>
            <div id="ot-sdk-cookie-policy"></div>
        </>
    );

    return (
        <OTScriptCookieList/>
    );

};

export default OTScriptCookieList;
