import React, {FunctionComponent} from 'react';
import Anchor from './Anchor';

interface Props {
    buttonHandler?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | (() => void);
    type?: 'button' | 'submit' | 'reset';
    className?: string;
    asLink?: string;
    disabled?: boolean;
}

const Button: FunctionComponent<Props> = (
    {
        type = 'button',
        buttonHandler,
        className = '',
        asLink,
        disabled,
        children
    }) => {

    const fullClassName = `button text-center px-6 py-2 border rounded-md font-bold uppercase ${className}`;

    if (!asLink) {
        return (
            <button
                type={type}
                className={fullClassName}
                onClick={buttonHandler}
                disabled={disabled}
            >
                {children}
            </button>
        );
    } else {
        return (
            <Anchor to={asLink} className={fullClassName}>
                {children}
            </Anchor>
        );
    }
};

export default Button;
