import React, {FunctionComponent} from 'react';
import {Link} from 'gatsby';
import ExternalLinkModalContent from './ExternalLinkModalContent';
import Modal from './Modal';
import {useDialogState} from 'reakit/Dialog';

interface Props {
    to: string;
    className?: string;
}

const Anchor: FunctionComponent<Props> = ({to, className, children}) => {
    const externalLinkDialog = useDialogState();

    if (to.startsWith('/')) {
        return <Link to={to} className={className ?? ''}>{children}</Link>;
    } else {
        return (
            <>
                <a
                    href={to}
                    onClick={(e) => {
                        e.preventDefault();
                        externalLinkDialog.toggle();
                    }}
                    className={className ?? ''}>
                    {children}
                </a>
                <Modal dialog={externalLinkDialog}>
                    <ExternalLinkModalContent externalLink={to} dialog={externalLinkDialog}/>
                </Modal>
            </>
        );
    }
};

export default Anchor;
