import ReactGA from 'react-ga';

export const useGoogleAnalytics = () => {
    ReactGA.initialize(process.env.GA as string);
    ReactGA.set({anonymizeIp: true});
    track(window.location.pathname);
};

export const track = (url: string) => {
    ReactGA.pageview(url);
};
