import React, {FunctionComponent} from 'react';

interface Props {
    link: string;
}

const Video: FunctionComponent<Props> = ({link}) => {
    return (
        <div className="video">
            <iframe
                src={link}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
                width="560"
                height="349"
            />
        </div>
    );
};

export default Video;
