import React, {FunctionComponent} from 'react';
import SanofiLogo from './SanofiLogo';
import SiteLogo from './SiteLogo';
import NavLink from '../../types/NavLink';
import Anchor from '../Anchor';
import {Link} from 'gatsby';
import {DialogDisclosure, useDialogState} from 'reakit/Dialog';
import Modal from '../Modal';
import ExternalLinkModalContent from '../ExternalLinkModalContent';

type FooterStructure = Array<Array<NavLink>>;

const Footer: FunctionComponent = () => {
    const {
        links,
        copyright,
        logoText
    }: { links: FooterStructure, copyright: string, logoText: string } = require(`../../content/${process.env.THEME}/footer.json`);
    const dialog = useDialogState();
    return (
        <footer className='px-6 pt-6 pb-2 text-sm text-white bg-teal-800 lg:stacky-6'>
            <nav className='container flex flex-col lg:flex-row mx-auto justify-between mb-4'>
                {
                    links.map((footerList, listIndex) => {
                        return (
                            <ul key={listIndex} className='pr-4'>
                                {footerList.map((item, itemIndex) => {


                                    return !(item.to === 'https://www.sanofi.nl/nl/contact') ?
                                        <li key={`${listIndex}-${itemIndex}`}>
                                            <Anchor to={item.to}>
                                                {item.title}
                                            </Anchor>
                                        </li> : <li key={`${listIndex}-${itemIndex}`}>
                                            <DialogDisclosure {...dialog}>
                                                <Anchor to={item.to}>
                                                    {item.title}
                                                </Anchor>
                                            </DialogDisclosure>
                                            <Modal dialog={dialog}>
                                                <ExternalLinkModalContent dialog={dialog}
                                                    externalLink={item.to}/>
                                            </Modal>
                                        </li>;

                                })
                                }
                            </ul>
                        );
                    })
                }
                <div className='align-middle'>
                    <SanofiLogo grayScale={true} className='text-white'/>
                </div>
            </nav>
            <div className='container flex flex-row mx-auto justify-between mb-4'>
                <SiteLogo name={logoText} link={'/'} classNames='block'/>
            </div>
            <div
                className='container max-w-xl text-xs text-center'
                dangerouslySetInnerHTML={{__html: copyright}}
            />
        </footer>
    );
};

export default Footer;
